import React, { useContext } from 'react';
import { FeatureFlagContext } from 'lib/ContextTypes';
import PropTypes from 'prop-types';
import Head from 'next/head';

import * as CustomPropTypes from 'lib/CustomPropTypes';
import {
  buildBreadcrumbs,
  buildDataset,
  buildItemList,
  buildLiveBlog,
  buildNewsArticle,
  buildListicleProduct,
  buildRecipeCarouselFromArticle,
  buildVideoObjectLDFromApiData,
} from 'lib/LDJson';
import { getLiveEventRegEx } from 'lib/LDJson/buildLiveBlog';
import { shareImage } from 'lib/social';
import { getCanonical } from 'lib/urlUtils';

import AMPHTMLLink from 'components/AMP/HTMLLink';
import { resolveOnTheShowMetadata, useBroadcastEvent, useFAQ } from './metadataUtils';

/**
 *
 * @param {object} article
 */
export function resolveArticleRobotMetadata(article) {
  const { nativeAd, searchable, sponsoredBy } = article;
  const NBCBrandedSponsorGeneratedContent = 'NBC News Brand Studio';
  const isNBCBrandedSponsorGeneratedContent = sponsoredBy && sponsoredBy.includes(
    NBCBrandedSponsorGeneratedContent,
  );
  const isNotSearchableArticle = !searchable;
  const isNativeAdAndNotSponsoredByNBC = nativeAd && !isNBCBrandedSponsorGeneratedContent;
  const isNativeAdAndSponsoredByNBC = nativeAd && isNBCBrandedSponsorGeneratedContent;

  if (isNotSearchableArticle || isNativeAdAndNotSponsoredByNBC) {
    return <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />;
  }
  if (isNativeAdAndSponsoredByNBC) return <meta name="ROBOTS" content="INDEX, NOFOLLOW" />;
  return null;
}

/**
 *
 * @param {object} article
 * @param {string} vertical
 */
function constructMetaData(article, vertical) {
  const {
    date: {
      modifiedAt,
      publishedAt,
    },
    description,
    headline,
    primaryImage,
    socialImage,
    taxonomy: {
      topics,
    },
    teaseImage,
    url,
  } = article;

  const image = socialImage || teaseImage || primaryImage || {};
  const imageUrl = (image.url?.primary);

  const resolvedArticleRobotMetadata = resolveArticleRobotMetadata(article);

  const onTheShowMetadata = resolveOnTheShowMetadata(article.taxonomy, vertical);

  const isOpinion = topics
    .filter(Boolean) // filter falsey values
    .some(({ slug }) => slug.toLowerCase() === 'opinion');

  const ogTitle = headline.social || headline.primary;
  const ogDescription = description.social || description.primary || description.seo;
  const ogImage = shareImage(imageUrl, vertical, 'og');
  const ogType = 'article';

  return (
    <Head>
      <title>{headline.seo || headline.primary}</title>
      <meta name="description" content={description.seo || description.primary} />
      <link rel="canonical" href={getCanonical(url)} />

      <meta property="og:url" content={url.primary} />
      <meta property="og:title" content={ogTitle} />
      <meta
        property="og:description"
        content={ogDescription}
      />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:secure_url" content={shareImage(imageUrl, vertical, 'og')} />
      <meta property="og:image:type" content={image.encodingFormat || 'image/gif'} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content={ogType} />

      <meta name="branch:deeplink:title" content={ogTitle} />
      <meta name="branch:deeplink:description" content={ogDescription} />
      <meta name="branch:deeplink:image" content={ogImage} />
      <meta name="branch:deeplink:type" content={ogType} />

      <meta name="twitter:image:src" content={shareImage(imageUrl, vertical, 'twitter')} />
      <meta name="twitter:image" content={shareImage(imageUrl, vertical, 'twitter')} />
      <meta name="twitter:title" content={headline.social || headline.primary} />
      <meta
        name="twitter:description"
        content={description.social || description.primary || description.seo}
      />
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="article:opinion" content={isOpinion.toString()} />
      <meta property="article:content_tier" content="free" />
      <meta property="article:published_time" content={publishedAt} />
      <meta property="article:modified_time" content={modifiedAt} />

      <meta name="thumbnail" content={shareImage(imageUrl, vertical)} />
      {resolvedArticleRobotMetadata}
      {onTheShowMetadata}
    </Head>
  );
}

/**
 *
 * @param {object} root0
 * @param {object} root0.article
 * @param {string} root0.vertical
 * @param {string} root0.currentPath
 * @param {boolean} root0.isShowBlog
 * @param {Array} root0.allVideos
 */
function ArticleMetadata({
  article,
  vertical,
  currentPath = '',
  isShowBlog = false,
  allVideos = [],
}) {
  const featureFlags = useContext(FeatureFlagContext);
  const breadcrumbsLdJson = vertical === 'select' ? buildBreadcrumbs(article) : null;
  const liveVideoJsonLdMetadata = useBroadcastEvent(article);
  const recipeCarousel = buildRecipeCarouselFromArticle(article, vertical);
  const faqsJsonLdMetadata = useFAQ(article);

  const products = article?.body?.filter((possibleProduct) => possibleProduct.type === 'embeddedProduct')
    .map((product) => {
      const { product: _product } = product;
      const {
        id, description, name, offers, teaseImage, prosAndCons,
      } = _product;
      const productUrl = offers[0]?.externalUrl ?? '#';
      const imageUrl = teaseImage?.url?.primary ?? '#';

      return JSON.parse(buildListicleProduct({
        id,
        name,
        description,
        offers,
        productUrl,
        imageUrl,
        prosAndCons,
        article,
      }));
    }) ?? [];

  const {
    taxonomy: {
      allTerms,
    },
  } = article;

  const isLiveEvent = (allTerms || []).find(({ path }) => path.match(getLiveEventRegEx(vertical)));

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(buildNewsArticle(article, vertical)),
          }}
        />
        {isLiveEvent && (
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(buildLiveBlog(article, vertical, featureFlags)),
            }}
          />
        )}
        {products.length && (
          <script
            type="application/ld+json"
            data-test="jsonld_embeddedProduct"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(buildItemList({ items: products })),
            }}
          />
        )}
        {liveVideoJsonLdMetadata}
        {allVideos.map((video) => (
          <script
            key={`VideoObjectLD-${video.id}`}
            type="application/ld+json"
            dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
              __html: JSON.stringify(buildVideoObjectLDFromApiData({ video, vertical })),
            }}
          />
        ))}
        {recipeCarousel && (
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: JSON.stringify(recipeCarousel) }}
          />
        )}
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(buildDataset(article, vertical)) }}
        />
        {/* conditionally adding FAQs ld+json if it exists in article data */}
        {faqsJsonLdMetadata}
        {breadcrumbsLdJson && (
          <script
            type="application/ld+json"
            data-test="jsonld_breadcrumbs"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbsLdJson) }}
          />
        )}
        {article.socialCopy?.social
          ? <meta name="socialCopy" content={article.socialCopy.social} />
          : null}
      </Head>

      {constructMetaData(article, vertical)}

      <AMPHTMLLink
        content={article}
        vertical={vertical}
        currentPath={currentPath}
        isShowBlog={isShowBlog}
      />
    </>
  );
}


ArticleMetadata.propTypes = {
  article: CustomPropTypes.article.isRequired,
  vertical: PropTypes.string.isRequired,
  currentPath: PropTypes.string,
  isShowBlog: PropTypes.bool,
};

export { ArticleMetadata };
