import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { VerticalContext } from 'lib/ContextTypes/vertical';
import facebookConfig from 'lib/facebookConfig.json';
import { getSiteConfigs } from 'lib/vertical';

const iconLink = (iconUrl) => (asset) => `https://nodeassets.nbcnews.com/cdnassets/projects/ramen/favicon/${iconUrl}/all-other-sizes-PNG.ico/${asset}`;

// hidden === "hidden publish"
function GlobalMetadata({
  hidden = false,
  webFaviconLinks = null,
}) {
  const vertical = useContext(VerticalContext);
  const fbData = facebookConfig[vertical] || facebookConfig.news;
  const siteConfig = getSiteConfigs(vertical);
  const {
    iconUrl,
    ogSiteName,
    twitterContentCreator,
    twitterSite,
  } = siteConfig;

  const getIconLink = iconLink(iconUrl);

  return (
    <Head>
      {/* WEB FAVICONS */}
      {(webFaviconLinks && webFaviconLinks(getIconLink)) || (
        <>
          <link key="favicon" rel="shortcut icon" type="image/x-icon" href={getIconLink('favicon.ico')} />
          <link rel="icon" type="image/png" sizes="16x16" href={getIconLink('favicon-16x16.png')} />
          <link rel="icon" type="image/png" sizes="32x32" href={getIconLink('favicon-32x32.png')} />
          <link rel="icon" type="image/png" sizes="96x96" href={getIconLink('favicon-96x96.png')} />
        </>
      )}

      {/* APPLE FAVICONS */}
      <link rel="apple-touch-icon" href={getIconLink('apple-icon.png')} />
      <link rel="apple-touch-icon-precomposed" href={getIconLink('apple-icon-precomposed.png')} />
      <link rel="apple-touch-icon" sizes="57x57" href={getIconLink('apple-icon-57x57.png')} />
      <link rel="apple-touch-icon" sizes="60x60" href={getIconLink('apple-icon-60x60.png')} />
      <link rel="apple-touch-icon" sizes="72x72" href={getIconLink('apple-icon-72x72.png')} />
      <link rel="apple-touch-icon" sizes="76x76" href={getIconLink('apple-icon-76x76.png')} />
      <link rel="apple-touch-icon" sizes="114x114" href={getIconLink('apple-icon-114x114.png')} />
      <link rel="apple-touch-icon" sizes="120x120" href={getIconLink('apple-icon-120x120.png')} />
      <link rel="apple-touch-icon" sizes="144x144" href={getIconLink('apple-icon-144x144.png')} />
      <link rel="apple-touch-icon" sizes="152x152" href={getIconLink('apple-icon-152x152.png')} />
      <link rel="apple-touch-icon" sizes="180x180" href={getIconLink('apple-icon-180x180.png')} />

      {/* ANDROID FAVICONS */}
      <link rel="manifest" href={getIconLink('manifest.json')} />
      <link rel="icon" sizes="36x36" href={getIconLink('android-icon-36x36.png')} />
      <link rel="icon" sizes="48x48" href={getIconLink('android-icon-48x48.png')} />
      <link rel="icon" sizes="72x72" href={getIconLink('android-icon-72x72.png')} />
      <link rel="icon" sizes="96x96" href={getIconLink('android-icon-96x96.png')} />
      <link rel="icon" sizes="144x144" href={getIconLink('android-icon-144x144.png')} />
      <link rel="icon" sizes="192x192" href={getIconLink('android-icon-192x192.png')} />

      {/* MS TILE ICONS */}
      <meta name="msapplication-config" content={getIconLink('browserconfig.xml')} />

      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="robots" content="max-image-preview:large" />
      {fbData.app_id && (
        <meta property="fb:app_id" content={fbData.app_id} />
      )}
      {fbData.admins?.length && fbData.admins.map((val) => (
        <meta property="fb:admins" content={val} key={val} />
      ))}
      {fbData.pages?.length && fbData.pages.map((val) => (
        <meta property="fb:pages" content={val} key={val} />
      ))}
      <meta property="og:site_name" content={ogSiteName} />
      <meta property="og:locale" content="en_US" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twitterSite} />
      <meta name="twitter:creator" content={twitterContentCreator} />

      {hidden && (
        <meta name="robots" content="NOINDEX, NOFOLLOW" />
      )}

      <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=yes" />
    </Head>
  );
}

GlobalMetadata.propTypes = {
  hidden: PropTypes.bool,
  webFaviconLinks: PropTypes.func,
};


export { GlobalMetadata };
